/* @jsx jsx */
import React from "react";
import { jsx, Box, Heading, Text, Grid, Link } from "theme-ui";

const TbaOverlay: React.FC<{ bgColor: string }> = ({ bgColor }) => {
  return (
    <Box
      sx={{
        pl: [0, `4`],
        pb: [0, `4`],
      }}
    >
      <Grid
        sx={{
          width: `100%`,
          p: `4`,
          backgroundColor: bgColor,
          borderRadius: `5`,
          textAlign: `center`,
          boxShadow: `0 4px 16px 0 #2E293314, 0 8px 24px 0 #473F4F14`,
        }}
      >
        <Heading as="h2" sx={{ color: `background`, fontSize: [`4`, `5`] }}>
          Claim Your Free Spot Now!
        </Heading>
        <Text sx={{ fontSize: [`2`, `3`], color: `background` }}>
          <Link
            sx={{
              color: `white`,
              textDecoration: `none`,
              fontWeight: `bold`,
            }}
            href="#register"
          >
            Register for free
          </Link>
          &nbsp;and&nbsp;
          <Link
            sx={{
              color: `white`,
              textDecoration: `none`,
              fontWeight: `bold`,
            }}
            href="https://twitter.com/gatsbyjs"
          >
            follow us on Twitter
          </Link>
          &nbsp;for the latest updates!&nbsp;
        </Text>
      </Grid>
    </Box>
  );
};

export default TbaOverlay;
