/* @jsx jsx */
import React, { useState } from "react";
import DayHeading from "./DayHeading";
import EventList from "./EventList";
import TbaOverlay from "./TbaOverlay";
import { jsx, Flex } from "theme-ui";
import { TRACK_1 } from "./TrackSelector";
const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc"); // dependent on utc plugin
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const layoutStyles = (blockColor, textColor) => ({
  color: textColor,
  flexDirection: `column`,
  width: `100%`,
  p: [`4`, null, `4`],
  pt: [`5`, null, `6`],
  pr: [`4`, null, `5`, `6`],
  borderRadius: [`6`, `9`],
  backgroundColor: blockColor,
});

const filterEventsByTrack = (events, track) =>
  events.filter((event) => event.track === "main" || event.track === track);

const Day: React.FC<{
  title?: string;
  description?: string;
  startTime?: string;
  blockColor: string;
  textColor: string;
  hoverColor: string;
  calendar: object;
  events: any;
}> = ({
  title,
  description,
  startTime,
  calendar,
  blockColor = `purple`,
  textColor = `magenta`,
  hoverColor = `vividLighter`,
  events,
}) => {
  const [track, setTrack] = useState(TRACK_1);

  const handleTrackChange = (e: object) => {
    e.preventDefault();
    setTrack(e.currentTarget.value);
  };

  const filteredEvents = filterEventsByTrack(events, track);

  return (
    <Flex as="section" sx={layoutStyles(blockColor, textColor)}>
      <DayHeading
        title={title}
        description={description}
        startTime={startTime}
        textColor={textColor}
        calendar={calendar}
      />
      <EventList
        events={filteredEvents}
        textColor={textColor}
        hoverColor={hoverColor}
        selectedTrack={track}
        onChangeTrack={handleTrackChange}
      />
      <TbaOverlay bgColor={textColor} />
    </Flex>
  );
};

export default Day;
