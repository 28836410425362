import React from "react";
import { Divider } from "theme-ui";

const dividerStyles = {
  marginLeft: [null, null, "150px"],
  opacity: ".25",
  width: ["100%", null, "calc(100% - 150px)"],
};

const StyledDivider = () => {
  return <Divider sx={dividerStyles} />;
};

export default StyledDivider;
