/* @jsx jsx */
import * as React from "react";
import { jsx, Box, Flex, Text, Grid, Badge } from "theme-ui";
import { Link, graphql } from "gatsby";
import Presenters from "./Presenters";
import StyledDivider from "./StyledDivider";
import { FaChevronRight } from "react-icons/fa";

const eventStyles = {
  gridTemplateAreas: [
    `
    "time" 
    "heading"
    "presenter"
    "description" 
    `, // moblile screens
    `
    "time heading heading"
    "time presenter presenter"
    `, // tablet portrait
    `
    "time heading presenter"
    `, // desktop and above
  ],
  py: ["2", "3", "4"],
};

const eventTextStyles = (eventType, textColor, isKeynote) => {
  let fontSize;
  let transform;
  switch (eventType) {
    case "general":
      fontSize = "3";
      transform = "translateY(3px)";
      break;
    default:
      fontSize = ["5", "5"];
  }
  if (isKeynote) {
    fontSize = ["5", "5", "6", "6"];
  }

  return {
    fontSize,
    fontWeight: `heading`,
    lineHeight: `heading`,
    color: textColor,
    letterSpacing: eventType === "general" ? "0.16em" : "0.02em",
    textTransform: eventType === "general" ? "uppercase" : "none",
    transform: transform,
  };
};

const eventHeadingStyles = {
  gridArea: `heading`,
};

const linkStyles = {
  position: `absolute`,
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  cursor: `pointer`,
};

const hoverStyles = (hoverColor) => ({
  // change color of event inner elements on hover
  "&:hover *": {
    color: hoverColor,
  },

  // stying to target the image filter and badge color
  [`&:hover .presenter > div`]: {
    backgroundColor: hoverColor,
  },
  [`&:hover .workshop-level > div`]: {
    borderColor: hoverColor,
  },
});

const listItemStyles = (hoverColor, shouldShowHoverStyles = true) => ({
  position: `relative`,
  listStyle: `none`,
  ...(shouldShowHoverStyles && hoverStyles(hoverColor)),
});

const presenterStyles = {
  gridArea: `presenter`,
  display: `grid`,
  gridTemplateRows: [null, null, `min-content min-content`],
  gridGap: `4`,
  mt: ["3", null, 0],
};

const timeStyles = {
  gridArea: `time`,
  display: `flex`,
  flexDirection: `column`,
};

const dlStyles = {
  alignSelf: [`flex-start`, `flex-end`],
  transform: [null, `translateY(3px)`],
};

const workshopLevelStyles = (textColor: string) => ({
  color: textColor,
  mr: "3",
});

const EventTime: React.FC<{
  startTime: string;
  endTime: string;
  datetime: string;
  textColor: string;
}> = ({ startTime, endTime, datetime, textColor }) => (
  <Box sx={{ ...timeStyles, color: textColor }}>
    <Box as="dl" sx={dlStyles}>
      <Text as="dt" variant="text.screenReader">
        Time of event
      </Text>
      <Box as="dd">
        <time dateTime={datetime}>{`${startTime} - ${endTime}`} </time>
      </Box>
    </Box>
  </Box>
);

const EventTitle: React.FC<{ event: object; textColor: string }> = ({
  event,
  textColor,
}) => {
  const isKeynote = !!event.title.includes(`Keynote`);
  return (
    <Text
      variant="talks"
      sx={{
        ...eventTextStyles(event.eventType, textColor, isKeynote),
      }}
    >
      {event.title}
    </Text>
  );
};

const EventDescription: React.FC<{ event: object; textColor: string }> = ({
  event,
  textColor,
}) => {
  return (
    <Flex sx={{ alignItems: "center", marginTop: "2" }}>
      {event.workshopLevel && (
        <Box className="workshop-level">
          <Badge sx={workshopLevelStyles(textColor)}>
            {event.workshopLevel}
          </Badge>
        </Box>
      )}
      <Text
        sx={{
          color: textColor,
          textDecoration: "underline",
          marginRight: "1",
        }}
      >
        Details
      </Text>
      <FaChevronRight sx={{ fontSize: "1", pt: "2px" }} />
    </Flex>
  );
};

const Event: React.FC<{
  event: any;
  startTime: string;
  endTime: string;
  textColor: string;
  hoverColor: string;
}> = ({ event, startTime, endTime, textColor, hoverColor }) => {
  const isTalkOrWorkshop =
    event.eventType === `workshop` || event.eventType === `talk`;
  return (
    <li
      sx={listItemStyles(hoverColor, isTalkOrWorkshop)}
      key={`${event.title}-${event.startTime}`}
    >
      <StyledDivider />
      {isTalkOrWorkshop && (
        <Link
          sx={linkStyles}
          to={event.gatsbyPath}
          aria-label={`Visit the details page for ${event.title}`}
        >
          <Text variant="text.screenReader">
            Visit the details page for {event.title}
          </Text>
        </Link>
      )}
      <Grid as="article" variant="schedule" sx={eventStyles}>
        <EventTime
          startTime={startTime}
          endTime={endTime}
          datetime={event.datetime}
          textColor={textColor}
        />
        <Box sx={eventHeadingStyles}>
          <EventTitle event={event} textColor={textColor} />
          {event?.description && (
            <EventDescription event={event} textColor={textColor} />
          )}
          {event.metaDescription && (
            <Text sx={{ color: textColor, marginTop: "4" }}>
              {event.metaDescription}
            </Text>
          )}
        </Box>

        <Box sx={presenterStyles}>
          <Presenters presenters={event?.presenters} textColor={textColor} />
        </Box>
      </Grid>
    </li>
  );
};

export default Event;

export const query = graphql`
  fragment Event on ContentfulEvent {
    id
    gatsbyPath(filePath: "/event/{ContentfulEvent.title}")
    title
    datetime
    duration
    eventType
    youtubeVideoLink
    workshopLevel
    track
    presenters {
      ...Presenter
    }
    prerequisites {
      raw
    }
    metaDescription
    description {
      raw
    }
    socialImage {
      file {
        url
      }
    }
  }
`;
