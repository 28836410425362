/* @jsx jsx */
import React from "react";
import { jsx } from "theme-ui";
import Event from "./Event";
import TrackSelector from "./TrackSelector";

const eventListStyles = {
  pl: 0,
  flexDirection: `column`,
  listStyle: "none",
};

const EventList: React.FC<{
  textColor: string;
  hoverColor: string;
  selectedTrack: string;
  onChangeTrack: (event: React.MouseEvent<HTMLButtonElement>) => void;
  events: any;
}> = ({ events, textColor, hoverColor, selectedTrack, onChangeTrack }) => {
  return (
    <ul sx={eventListStyles}>
      {events.map((event: object, index: number) => {
        if (event.eventType === "meta") {
          return (
            <TrackSelector
              selectedTrack={selectedTrack}
              onChangeTrack={onChangeTrack}
            />
          );
        }

        return (
          <Event
            event={event}
            startTime={event.startTime}
            endTime={event.endTime}
            textColor={textColor}
            hoverColor={hoverColor}
          />
        );
      })}
    </ul>
  );
};

export default EventList;
