/* @jsx jsx */
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { StaticImage } from "gatsby-plugin-image";
import { jsx, Box, Grid, Heading, Text } from "theme-ui";
import ConfLogo from "./ConfLogo";
import Flex from "./Flex";
import RichText from "./RichText";
import HubspotForm from "./HubspotForm";

const heroQuery = graphql`
  {
    hero: contentfulHero {
      contentful_id
      id
      eventName
      title
      titleSuffix
      lede
      description {
        raw
      }
      hubspotForm {
        title
        decorativeText
        portalId
        formId
        hopinId
      }
    }
    confDetails: contentfulConferenceDetails {
      logo {
        file {
          url
        }
      }
    }
    allRegistrant {
      totalCount
    }
  }
`;

const heroLayoutStyles = {
  width: `100%`,
  px: [4, 5, 6, 0],
  py: [3, 4, 5],
};

const backgroundImgWrapperStyles = {
  position: `absolute`,
  top: [32, 0],
  left: 0,
  right: 0,
  zIndex: -1,
  height: `100%`,
  width: `100%`,
  maxWidth: 1500, // there's nothing special about this number, just looks good at this width
  margin: `0 auto`,
};

const backgroundImgStyles = {
  width: `100%`,
  maxWidth: 1500,
  height: `auto`,
  opacity: 0.1,
  // mixBlendMode: `color-dodge`, // not sure this has enough support across browsers to replicate what we have in Figma
};

const logoStyles = {
  width: `auto`,
  height: 72,
  alignSelf: [`center`, `flex-start`],
  transform: [`inherit`, `translateX(-50px)`],
};

const textGridStyles = { gridTemplateRows: `repeat(3, min-content)` };

const headingStyles = {
  fontSize: [`7`, `8`],
  textAlign: [`center`, `left`],
  fontWeight: `heading`,
};

const ledeStyles = {
  fontSize: `4`,
  textAlign: [`center`, `left`],
  color: `secondary`,
  variant: `text.capitalized`,
};

const Hero: React.FC<{}> = () => {
  const data = useStaticQuery(heroQuery);
  return (
    <Flex
      as="section"
      alignItems="flex-start"
      flexDirection="column"
      sx={heroLayoutStyles}
      gap={[3, 4, 5]}
    >
      <Box sx={backgroundImgWrapperStyles}>
        <StaticImage
          alt=""
          sx={backgroundImgStyles}
          src="../images/hero-background.png"
          formats={["auto", "webp", "avif"]}
        />
      </Box>
      <ConfLogo sx={logoStyles} />
      <Grid gap={[3, 4, 5, 6]} columns={[1, 1, 1, 2]}>
        <Grid sx={textGridStyles} gap={4} columns={1}>
          <Heading as="h1" sx={headingStyles}>
            <Text variant="gradient">{data.hero.title}</Text>
            <Text>{data.hero.titleSuffix}</Text>
          </Heading>
          <Text sx={ledeStyles}>{data.hero.lede}</Text>
          <RichText
            richTextNode={data.hero.description}
            align={[`center`, `left`]}
          />
        </Grid>
        <div id="register">
          <HubspotForm
            decorativeText={`${data.allRegistrant.totalCount} people have already signed up!`}
          />
        </div>
      </Grid>
    </Flex>
  );
};

export default Hero;
