/* @jsx jsx */
import * as React from "react";
import { jsx, Grid } from "theme-ui";
import { useStaticQuery, graphql } from "gatsby";
import Day from "./Day";
const dayjs = require("dayjs");

const eventTimeFormat = "H:mm";

const sectionStyles = {
  width: `100%`,
  px: [null, `4`],
  py: [`3`, `3`, `5`, `6`],
  gridGap: `6`,
  contentVisibility: `auto`,
  containIntrinsicSize: 7575,
};

const formatLocalTimes = (events: object) => {
  return events.map((event) => {
    const eventTime = dayjs(event.datetime);
    const startTime = `${eventTime.format(eventTimeFormat)}`;
    const endTime = `${eventTime
      .add(+event.duration, "m")
      .format(eventTimeFormat)}`;

    return {
      ...event,
      startTime,
      endTime,
    };
  });
};

const Schedule: React.FC<{}> = () => {
  const data = useStaticQuery(graphql`
    {
      talks: contentfulSchedule(title: { eq: "Talks" }) {
        id
        title
        description
        dateline
        startTime
        googleCal {
          googleCal
        }
        icsCal
        events {
          ...Event
        }
      }
      workshops: contentfulSchedule(title: { eq: "Workshops" }) {
        id
        title
        description
        dateline
        startTime
        googleCal {
          googleCal
        }
        icsCal
        events {
          ...Event
        }
      }
    }
  `);

  return (
    <Grid as="section" sx={sectionStyles}>
      <Day
        title={data.talks.title}
        description={data.talks.description}
        dateline={data.talks.dateline}
        startTime={data.talks.startTime}
        events={formatLocalTimes(data.talks.events)}
        blockColor="purpleBackground"
        textColor="vivid"
        hoverColor="vividLighter"
        calendar={{
          googleCal: data.talks.googleCal.googleCal,
          iCal: data.talks.icsCal,
        }}
      />
      <Day
        title={data.workshops.title}
        description={data.workshops.description}
        dateline={data.workshops.dateline}
        startTime={data.workshops.startTime}
        events={formatLocalTimes(data.workshops.events)}
        blockColor="magentaBackground"
        textColor="magenta"
        hoverColor="hover.magenta"
        calendar={{
          googleCal: data.workshops.googleCal.googleCal,
          iCal: data.workshops.icsCal,
        }}
      />
    </Grid>
  );
};

export default Schedule;
