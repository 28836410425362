/* @jsx jsx */
import React from "react";
import { MdArrowDownward } from "react-icons/md";
import { jsx, Flex, Grid, Button } from "theme-ui";
import StyledDivider from "./StyledDivider";

const showActiveTrackButton = (track: string, selectedTrack: string) => {
  return track === selectedTrack ? "track" : "inactive";
};

const TrackSelector: React.FC<{
  selectedTrack: string;
  onChangeTrack: (event: React.MouseEvent<HTMLButtonElement>) => void;
}> = ({ selectedTrack, onChangeTrack }) => {
  return (
    <li key="track-selector">
      <StyledDivider />
      <Grid variant="schedule" sx={{ paddingTop: "5", paddingBottom: "3" }}>
        <Flex
          sx={{
            gridColumn: "2 / -1",
            justifyContent: ["space-evenly", "flex-start"],
            alignItems: "center",
          }}
        >
          <Button
            variant={showActiveTrackButton(TRACK_1, selectedTrack)}
            value={TRACK_1}
            onClick={onChangeTrack}
            sx={{ marginRight: ["0", "3"] }}
          >
            Track 1
            <MdArrowDownward sx={{ verticalAlign: "text-top", ml: "2" }} />
          </Button>
          <Button
            variant={showActiveTrackButton(TRACK_2, selectedTrack)}
            value={TRACK_2}
            onClick={onChangeTrack}
          >
            Track 2
            <MdArrowDownward sx={{ verticalAlign: "text-top", ml: "2" }} />
          </Button>
        </Flex>
      </Grid>
    </li>
  );
};

export const TRACK_1 = "track_1";
export const TRACK_2 = "track_2";
export default TrackSelector;
