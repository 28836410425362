/* @jsx jsx */
import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import { jsx, Badge, Box, Flex, Heading, Link, Text, Grid } from "theme-ui";

const speakersQuery = graphql`
  {
    allContentfulSpeaker {
      nodes {
        name
        title
        twitterHandle
        isEmcee
        headshot {
          id
          localFile {
            childImageSharp {
              gatsbyImageData(
                width: 233
                height: 233
                layout: FIXED
                formats: [AUTO, WEBP, AVIF]
                transformOptions: { fit: COVER, cropFocus: CENTER }
              )
            }
          }
        }
      }
    }
  }
`;

const Speaker: React.FC<{
  name: string;
  title: string;
  twitterHandle: string;
  image: object;
  isEmcee: boolean;
}> = ({ name, title, twitterHandle, image, isEmcee }) => {
  const randomColor = Math.random() > 0.5 ? `magenta` : `vivid`;
  const imageStyles = {
    position: `relative`,
    width: `233px`,
    height: `233px`,
    borderRadius: Math.random() > 0.5 ? "6" : "5",
    backgroundPosition: `center`,
  };

  return (
    <Flex
      sx={{
        width: `min-content`,
        justifySelf: `center`,
        flexDirection: "column",
        fontSize: `3`,
      }}
    >
      <Box
        sx={{
          position: `relative`,
          backgroundColor: randomColor,
          isolation: "isolate",
          mb: `3`,
          ...imageStyles,
        }}
      >
        <Badge
          sx={{
            color: `background`,
            display: isEmcee ? `flex` : `none`,
            position: `absolute`,
            fontSize: `1`,
            fontWeight: `bold`,
            backgroundColor: randomColor,
            borderRadius: 9999,
            borderColor: randomColor,
            px: `2`,
            py: `1`,
            zIndex: 5,
            bottom: 16,
            right: 16,
          }}
        >
          MC
        </Badge>
        <GatsbyImage
          sx={{
            objectFit: "cover",
            alignSelf: "center",
            filter: `grayscale(1)`,
            mixBlendMode: "multiply",
            ...imageStyles,
          }}
          loading="lazy"
          image={image?.localFile?.childImageSharp?.gatsbyImageData}
          alt={name}
        />
      </Box>
      <Heading as="h3" sx={{ color: randomColor, fontSize: `4` }}>
        {name}
      </Heading>
      <Text sx={{ fontSize: `3` }}>{title}</Text>
      {twitterHandle && (
        <Link
          sx={{
            textDecoration: `underline`,
          }}
          variant="text.link"
          href={`https://twitter.com/${twitterHandle}`}
        >
          {twitterHandle && `@${twitterHandle}`}
        </Link>
      )}
    </Flex>
  );
};

const speakersGridStyles = {
  gridTemplateColumns: `repeat(auto-fill, minmax(233px, 1fr))`,
  gridGap: `5`,
  px: `48px`,
};

const SpeakersList: React.FC<{ speakers: any }> = ({ speakers }) => {
  return (
    <Grid sx={speakersGridStyles}>
      {speakers.map((speaker: any) => {
        return (
          <Speaker
            key={speaker.name}
            name={speaker.name}
            title={speaker.title}
            twitterHandle={speaker.twitterHandle}
            isEmcee={speaker.isEmcee}
            image={speaker.headshot}
          />
        );
      })}
    </Grid>
  );
};

const sortSpeakers = (speakers: object[]) => {
  return speakers.sort((a, b) => {
    // could optimize this fx if perf is still an issue
    let lastNameA = a?.name?.trim().split(" ").pop().toUpperCase();
    let lastNameB = b?.name?.trim().split(" ").pop().toUpperCase();

    if (lastNameA < lastNameB) return -1;
    if (lastNameA > lastNameB) return 1;
    return 0;
  });
};

const Speakers: React.FC<{}> = () => {
  let {
    allContentfulSpeaker: { nodes: speakersData },
  } = useStaticQuery(speakersQuery);

  speakersData = sortSpeakers(speakersData);

  return (
    <Flex
      as="section"
      sx={{
        flexDirection: "column",
        my: [`5`, `6`],
        width: "100%",
        contentVisibility: `auto`,
        containIntrinsicSize: 8000,
      }}
    >
      <Heading
        as="h2"
        sx={{ textAlign: "center", marginBottom: [4, 4, 5], fontSize: `7` }}
      >
        Speakers
      </Heading>
      <SpeakersList speakers={speakersData} />
    </Flex>
  );
};

export default Speakers;
