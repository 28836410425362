/* @jsx jsx */
import * as React from "react";
import { jsx, Box, Flex as TUFlex, Heading, Text, Link } from "theme-ui";
import Flex from "./Flex";
import AgilityLogo from "../images/Agility.svg";
import ButterCMSLogo from "../images/ButterCMS.svg";
import CloudinaryLogo from "../images/Cloudinary.svg";
import CommercelayerLogo from "../images/Commercelayer.svg";
import ContentfulLogo from "../images/Contentful.svg";
import ContentstackLogo from "../images/Contentstack.svg";
import DatoCMSLogo from "../images/Dato_CMS.svg";
import FastlyLogo from "../images/Fastly.svg";
import FlotiqLogo from "../images/Flotiq.svg";
import imgIXLogo from "../images/imgIX.svg";
import KontentLogo from "../images/Kontent.svg";
import MediaCurrentLogo from "../images/MediaCurrent.svg";
import NetlifyLogo from "../images/Netlify.svg";
import PantheonLogo from "../images/Pantheon.svg";
import PeakZebraLogo from "../images/PeakZebra.svg";
import SanityLogo from "../images/Sanity.svg";
import SnipcartLogo from "../images/Snipcart.svg";
import StrapiLogo from "../images/Strapi.svg";
import TechEventsLogo from "../images/TechEvents.svg";
import ThirdAndGroveLogo from "../images/ThirdAndGrove.svg";
import JambareeLogo from "../images/Jambaree.svg";
import NacelleLogo from "../images/Nacelle.svg";
import OutsmartlyLogo from "../images/Outsmartly.svg";
import WPengineLogo from "../images/WPengine.svg";
import { MdArrowForward } from "react-icons/md";

const logoStyles = {
  height: [80, 80, 112],
  width: "auto",
  // marginBottom: [0, `4`],
  margin: [`2`, `4`],
  transition: `0.2s transform ease-in-out`,
  willChange: `transform`,
  "&:hover": {
    transform: `scale(1.05)`,
  },
};

const paragraphStyles = {
  color: "yellow.60",
  fontSize: ["2", "3", "4"],
  lineHeight: "1.5",
  textAlign: "center",
  maxWidth: `40ch`,
};

const themeUiFlexStyles = {
  flexWrap: "wrap",
  flexDirection: ["column", "row"],
  justifyContent: "center",
  alignItems: "center",
};

const SponsorLogo = ({
  to,
  src,
  alt,
}: {
  to: string;
  src: string;
  alt: string;
}) => {
  return (
    <Box>
      <Link href={to}>
        <img src={src} alt={alt} sx={logoStyles} />
      </Link>
    </Box>
  );
};

const Sponsors: React.FC<{}> = () => {
  return (
    <Flex
      as="section"
      flexDirection="column"
      alignItems="center"
      sx={{
        padding: "3",
        width: "100%",
        contentVisibility: `auto`,
        containIntrinsicSize: 1775,
      }}
      gap={5}
    >
      <Flex
        flexDirection="column"
        alignItems="center"
        sx={{ color: "yellow.60" }}
        gap={[2, 3]}
      >
        <Box>
          <Heading as="h1" sx={{ fontWeight: "heading", fontSize: ["6", "7"] }}>
            Sponsors
          </Heading>
        </Box>
      </Flex>
      <Flex flexDirection="column" alignItems="center" gap={[2, 4]}>
        <Box>
          <Heading as="h2" sx={{ color: "yellow.60", fontSize: ["4", "5"] }}>
            Gold Sponsors
          </Heading>
        </Box>
        <TUFlex sx={themeUiFlexStyles}>
          <SponsorLogo
            to="https://buttercms.com/"
            src={ButterCMSLogo}
            alt="Butter CMS"
          />
          <SponsorLogo
            to="https://www.contentful.com/"
            src={ContentfulLogo}
            alt="Contentful"
          />
          <SponsorLogo
            to="http://www.contentstack.com"
            src={ContentstackLogo}
            alt="Contentstack"
          />
          <SponsorLogo to="https://flotiq.com" alt="Flotiq" src={FlotiqLogo} />
          <SponsorLogo
            to="https://www.imgix.com/"
            alt="imgIX"
            src={imgIXLogo}
          />
          <SponsorLogo to="https://kontent.ai" alt="Kontent" src={KontentLogo} />
          <SponsorLogo
            to="https://www.netlify.com/"
            alt="Netlify"
            src={NetlifyLogo}
          />
          <SponsorLogo
            to="https://pantheon.io/"
            alt="Pantheon"
            src={PantheonLogo}
          />
          <SponsorLogo
            to="https://www.strapi.io/"
            alt="Strapi"
            src={StrapiLogo}
          />
          <SponsorLogo
            to="https://wpengine.com/"
            alt="WPengine"
            src={WPengineLogo}
          />
        </TUFlex>
      </Flex>
      <Flex flexDirection="column" alignItems="center" gap={[2, 4]}>
        <Box>
          <Heading as="h2" sx={{ color: "yellow.60", fontSize: ["4", "5"] }}>
            Silver Sponsors
          </Heading>
        </Box>
        <TUFlex sx={themeUiFlexStyles}>
          <SponsorLogo
            to="https://agilitycms.com/"
            alt="AgilityCMS"
            src={AgilityLogo}
          />
          <SponsorLogo
            to="https://cloudinary.rocks/y1w"
            alt="Cloudinary"
            src={CloudinaryLogo}
          />
          <SponsorLogo
            to="https://commercelayer.io/"
            alt="commercelayer"
            src={CommercelayerLogo}
          />
          <SponsorLogo
            to="https://www.fastly.com/"
            alt="Fastly"
            src={FastlyLogo}
          />
          <SponsorLogo
            to="https://jambaree.com/"
            alt="Jambaree"
            src={JambareeLogo}
          />

          <SponsorLogo
            to="https://www.mediacurrent.com/"
            alt="MediaCurrent"
            src={MediaCurrentLogo}
          />
          <SponsorLogo
            to="https://getnacelle.com/"
            alt="Nacelle"
            src={NacelleLogo}
          />
          <SponsorLogo
            to="https://www.outsmartly.com/"
            alt="Outsmartly"
            src={OutsmartlyLogo}
          />
          <SponsorLogo
            to="https://www.sanity.io/"
            alt="Sanity"
            src={SanityLogo}
          />
          <SponsorLogo
            to="https://snipcart.com/"
            alt="Snipcart"
            src={SnipcartLogo}
          />
          <SponsorLogo
            to="https://www.techevents.online/"
            alt="Tech Events Online"
            src={TechEventsLogo}
          />
          <SponsorLogo
            to="https://www.thirdandgrove.com/"
            alt="Third and Grove"
            src={ThirdAndGroveLogo}
          />
        </TUFlex>
      </Flex>
      <Flex flexDirection="column" alignItems="center" gap={[2, 4]}>
        <Box>
          <Heading as="h2" sx={{ color: "yellow.60", fontSize: ["4", "5"] }}>
            Bronze Sponsors
          </Heading>
        </Box>
        <TUFlex sx={themeUiFlexStyles}>
          <SponsorLogo
            to="https://www.datocms.com/"
            alt="DatoCMS"
            src={DatoCMSLogo}
          />
          <SponsorLogo
            to="https://www.peakzebra.com/"
            alt="PeakZebra"
            src={PeakZebraLogo}
          />
        </TUFlex>
      </Flex>
      <Flex
        flexDirection="column"
        alignItems="center"
        gap={3}
        sx={{ width: "maxContext" }}
      >
        <Box>
          <Text sx={paragraphStyles}>Interested in becoming a sponsor?</Text>
        </Box>
        <Link
          href="https://forms.gle/C467newjxDcp3LHc9"
          sx={{
            variant: "buttons.primary",
            textDecoration: `none`,
            background: "none",
            backgroundColor: "yellow.60",
            color: "background",
            display: "flex",
            alignItems: "center",
          }}
        >
          Let us know <MdArrowForward sx={{ marginLeft: "2" }} />{" "}
        </Link>
      </Flex>
    </Flex>
  );
};

export default Sponsors;
