/* @jsx jsx */
import { jsx } from "theme-ui";
import { PageProps, graphql } from "gatsby";
import { Helmet } from "react-helmet";
import Layout from "../components/Layout";
import Flex from "../components/Flex";
import Hero from "../components/Hero";
import Schedule from "../components/Schedule";
import Speakers from "../components/Speakers";
import Sponsors from "../components/Sponsors";

type DataProps = {
  contentfulConferenceDetails: {
    name: string;
    description: string;
  };
};

const IndexPage = ({ data }: PageProps<DataProps>) => {
  return (
    <Layout>
      <Helmet>
        <title>{data.contentfulConferenceDetails.name}</title>
        <meta
          name="description"
          content={data.contentfulConferenceDetails.description}
        />
        <html lang="en" />
      </Helmet>
      <Flex gap={3} flexDirection="column" alignItems="center">
        <Hero />
        <Schedule />
        <Speakers />
        <Sponsors />
      </Flex>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  {
    contentfulConferenceDetails {
      name
      description
    }
  }
`;
