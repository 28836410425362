/* @jsx jsx */
import React from "react";
import { jsx, Box, Heading, Text, Grid, Flex } from "theme-ui";

const dayjs = require("dayjs");
const utc = require("dayjs/plugin/utc"); // dependent on utc plugin
const timezone = require("dayjs/plugin/timezone");
dayjs.extend(utc);
dayjs.extend(timezone);

const scheduleTimeFormat = "MMMM D, YYYY • h:mm A";

const dateStyles = (textColor: string) => ({
  color: textColor,
  fontSize: "2",
  fontWeight: "bold",
  lineHeight: "heading",
  letterSpacing: "0.12em",
  textAlign: "left",
});

const DateHeading: React.FC<{ startTime?: string; textColor: string }> = ({
  startTime,
  textColor,
}) => (
  <Text sx={dateStyles(textColor)}>
    {`${dayjs(startTime)
      .tz("America/Los_Angeles")
      .format(scheduleTimeFormat)} PST (GMT -8)`.toUpperCase()}
  </Text>
);

const headingGridStyles = {
  paddingBottom: ["3", "4", null, "5"],
};
const headingContainerStyles = {
  gridColumn: [null, "2 / -1", "2 / -1"],
};

const headingTextStyles = (textColor: string) => ({
  fontWeight: `heading`,
  lineHeight: `heading`,
  color: textColor,
  marginTop: "2",
  fontSize: [`6`, `6`, `7`, `7`],
});

const headingDescriptionStyles = (textColor: string) => ({
  color: textColor,
  paddingTop: `3`,
  fontSize: [`2`, null, `3`, `4`],
  lineHeight: `body`,
  letterSpacing: `0.02em`,
});

const DayHeading: React.FC<{
  title?: string;
  description?: string;
  startTime?: string;
  textColor: string;
  calendar: object;
}> = ({ title, description, startTime, calendar, textColor }) => (
  <Grid variant="schedule" sx={headingGridStyles}>
    <Box sx={headingContainerStyles}>
      <DateHeading textColor={textColor} startTime={startTime} />
      <Heading as="h2" sx={headingTextStyles(textColor)}>
        {title}
      </Heading>
      <Text sx={headingDescriptionStyles(textColor)}>{description}</Text>
      <Flex
        sx={{
          color: textColor,
          marginTop: `3`,
          flexDirection: [`column`, `row`],
        }}
      >
        <a
          sx={{
            color: textColor,
            display: `block`,
            mr: `4`,
            my: [`3`, `0`],
          }}
          href={calendar.googleCal}
        >
          Google Calendar
        </a>
        <a sx={{ color: textColor, display: `block` }} href={calendar.iCal}>
          iCal / Outlook
        </a>
      </Flex>
    </Box>
  </Grid>
);

export default DayHeading;
